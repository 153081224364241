import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { environment } from '@tytapp/environment';
import { Redirection, AppConfig } from '../../common';

@Component({
    selector: 'tyt-redirection',
    templateUrl: './redirection.component.html',
    styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent implements OnInit {
    private route = inject(ActivatedRoute);
    private redirection = inject(Redirection);

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.redirection.go(data.url.replace(/\{accounts\}/, environment.urls.accounts));
        });
    }

}

export function externalRedirect(path: string, url: string): Route {
    return { path, component: RedirectionComponent, data: { url }};
}